<template>
<div class="here bg-fff py-5 mb-3">
    <div class="container bg-fff in-here">
        <div class="row pb-4">
            <div class="col-md-11 mx-auto text-left px-0">
                <h2 class="title-dados pb-2 pt-5">Bem-vindo(a), Investidor Skaki!</h2>
                <div class="shadow-sm border-default shadow-sm mt-3 div-father-grafic">
                    <div class="col-md-10 mx-auto pb-5 pl-0">
                        <h2 class="title-in-dados title-dados pb-2 mb-2">Novos Usuários Por Mês</h2>
                        <canvas id="myChart" width="400" height="300"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Bar
} from 'vue-chartjs'
export default {
    extends: Bar,
    mounted() {
        var ctx = document.getElementById('myChart').getContext('2d');
        let chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                datasets: [{
                        label: 'First dataset',
                        // data: [0, 5, 10, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
                        data: [7, 25, 3, 60, 40, 5, 60, 45, 30, 55, 80, 99],
                        backgroundColor: 'transparent',
                        borderColor: 'rgb(255,99,132)'
                    },
                    {
                        label: 'Second dataset',
                        data: [0, 15, 10, 20, 25, 90, 35, 40, 45, 50, 55, 60, 65, 60, 75, 80, 85, 90, 95, 100],
                        backgroundColor: 'transparent',
                        borderColor: 'rgb(59,162,235)'
                    },
                ],
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 50,
                            suggestedMax: 100
                        }
                    }]
                },
            },
        }, {
            responsive: true
        });
    }
}
</script>

<style scoped>
</style>
